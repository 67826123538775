<template>
  <div class="pilots">
    <el-row :gutter="10">
      <el-col :span="24" :md="12"><PilotListing /></el-col>
      <el-col :span="24" :md="12"><TugBoatListing /></el-col>
    </el-row>
  </div>
</template>
<script>
import PilotListing from "./compenents/PilotListing"
import TugBoatListing from "./compenents/TugBoatListing"
export default {
  name: "PilotManagement",
  components: {
    PilotListing,
    TugBoatListing,
  },
  data() {
    return {

    }
  },
}
</script>
<style lang="scss">
.pilots {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>