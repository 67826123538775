<template>
  <div class="h-full overflow-hidden">
    <div class="listing">
      <div class="flex justify-between w-full items-center p-1 laptop:pb-2 desktop:pb-8">
        <div class="flex title">Tug Boat</div>
        <div class="flex">
          <div style="width: 275px; padding-right: 5px;">
            <el-input :placeholder="$t('btn.search')" size="mini" v-model="searchValue"
              @keyup.enter.native="getList"
            >
              <span slot="suffix" @click="getList"><svg-icon iconClass="search" /></span>
            </el-input>
          </div>
          <el-button type="primary" @click="dialogFormVisible = true" size="mini">
            {{ $t("table.addTugBoat") }}
          </el-button>
        </div>
      </div>
      <div>
        <el-table
          :header-cell-style="{ height: '55px', background: '#fff', 'border-bottom': '2px solid #D6D9E1'}"
          v-loading="listLoading"
          :cell-style="getCellStyle"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('table.no')" align="left" />
          <el-table-column prop="name" :label="$t('table.name')" align="left" />
          <el-table-column prop="createdBy" :label="$t('table.createdBy')" align="left" />
          <el-table-column prop="createdOn" :label="$t('table.createdOn')" align="left" />
          <el-table-column align="left" fixed="right" >
            <template slot-scope="scope">
              <el-button type="primary" size="mini" plain
                @click.native.prevent="deleteRow(scope.row.id)" 
              >{{ $t("btn.delete") }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog width="360px" :title="$t('table.addTugBoat')" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="form" :rules="rules" size="mini" label-position="top">
        <el-form-item prop="name" :label="$t('table.name')">
          <el-input size="mini" v-model="form.name" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" plain @click="dialogFormVisible = false">{{ $t("btn.close") }}</el-button>
        <el-button size="mini" type="primary" @click="handleSave">{{ $t("btn.save") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCellStyle } from "@/utils/getCellStyle";
import { getTugBoatList, saveTugBoat, deleteTugBoat } from "@/services/form";
export default {
  name: "TugBoatListing",
  data() {
    return {
      searchValue: null,
      total: 0,
      dialogFormVisible: false,
      listLoading: false,
      params: {
        pageNum: 1,
        pageSize: 9999,
      },
      tableData: [],
      form: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "Please Input", trigger: "blur" },
        ],
      },
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    async getList() {
      const params = { ...this.params, searchValue: this.searchValue }
      this.listLoading = true
      await getTugBoatList( params ).then(res => {
        this.total = res.data.table.total
        this.tableData = res.data.table.rows.filter(item => item.deleted == false)
      }).finally(() => {
        this.listLoading = false
      })
    },
    handleSave() {
      this.$refs["form"].validate(async valid => {
        if(valid) {
          await saveTugBoat(this.form).then(async res => {
            await this.getList()
            this.dialogFormVisible = false
          })
        }
      })
    },
    async deleteRow(id) {
      await deleteTugBoat(id).then( async res => {
        await this.getList()
      })
    },
    getCellStyle,
  }
}
</script>
<style lang="scss" scoped>
.listing {
  border-radius: 10px;
  padding:20px 0px;
  background-color: #fff;
  margin-bottom: 10px;
}
.title {
  text-indent: 10px;
  font-weight: 600;
  font-size: 12px;
  color: #1D1B20;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  font-weight: bold;
}
::v-deep .el-dialog__title {
  color: #82889c;
  font-size: 16px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
}
::v-deep .el-table th.el-table__cell {
  font-size: 12px;
  font-weight: bold;
}
::v-deep .el-table td.el-table__cell div {
  font-weight: 400;
  font-size: 11px;

  color: #2e3139;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    svg {
      width: 13px;
      height: 13px;
      cursor: pointer;
    }
  }
}
</style>